import React, { useEffect, useState } from 'react'
import Layout from '../layout/layout'
import { useAuth } from '../context/authContext'
import Error from '../pages/Errors/error'

const AuthGuard = ({ component }) => {
  const { isLogin } = useAuth()
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  useEffect(() => {
    setIsAuthenticated(isLogin)
  }, [isLogin])

  return (
    isAuthenticated != null && (
      isAuthenticated ? <Layout>{component}</Layout> : <Error code={401} auth={false} />
    )
  )
}

export default AuthGuard