// Layout.js
import React from 'react';
import Topbar from './topbar';
import Sidebar from './sidebar';
import Footer from './footer';

const Layout = ({ children }) => {
  return (
    <div className='wrapper'>
        <Topbar />
        <Sidebar />

        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    </div>
  );
};

export default Layout