import axios from "axios"

export const checkLogin = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://api.wizhull.com/login`,
            data: data
        })
        
        return {
            status: response.data.status,
            message: response.data.message,
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.response.data.message
        }
    }
}