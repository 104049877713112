import React from "react"
import { Route } from "react-router-dom"
import AuthGuard from "../guards/authGuards"
import Overview from "../pages/Dashboard/overview"
import About from "../pages/About/About"
import ChatbotLeads from "../pages/Leads/ChatbotLeads"
import ContactLeads from "../pages/Leads/ContactLeads"
import NewsletterLeads from "../pages/Leads/NewsletterLeads"


const AuthRoutes = [
    <Route key="Dashboard" path="/dashboard" element={<AuthGuard component={<Overview />} />} />,
    <Route key="About" path="/about" element={<AuthGuard component={<About />} />} />,
    <Route key="ChatbotLeads" path="/chatbot-leads" element={<AuthGuard component={< ChatbotLeads/>} />} />,
    <Route key="ContactsLeads" path="/contact-leads" element={<AuthGuard component={<ContactLeads />} />} />,
    <Route key="NewsletterLeads" path="/newsletter-leads" element={<AuthGuard component={<NewsletterLeads />} />} />,
]

export default AuthRoutes