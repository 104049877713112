import axios from "axios"

export const fetchContactsLeads = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://api.wizhull.com/fetchContacts`
        })
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchChatbotLeads = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://api.wizhull.com/fetchChatbotLeads`
        })
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchNewsletterLeads = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://api.wizhull.com/fetchNewsletter`
        })
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}

export const fetchOverview = async () => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://api.wizhull.com/fetchOverview`
        })
        return {
            status: "success",
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status"
        }
    }
}