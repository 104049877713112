import React, { createContext, useContext, useState } from 'react'
import CryptoJS from 'crypto-js'

const AuthContext = createContext()
const secKey = process.env.REACT_APP_SECRET_TOKEN

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false)

  const login = async() => {
    let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(new Date().setMinutes(new Date().getMinutes() + 1)), secKey).toString()
    localStorage.setItem("ttk", encryptedData)
    setIsLogin(true)
  }

  const checkLogin = async() => {
    let checkData = localStorage.getItem("ttk")
    if(checkData) {
      let decryptedData = CryptoJS.AES.decrypt(checkData, secKey).toString(CryptoJS.enc.Utf8);
      if(decryptedData > new Date) {
        setIsLogin(true)
      } else {
        logout()
      }
    }
  }

  const logout = () => {
    localStorage.clear()
    setIsLogin(false)
  }

  return (
    <AuthContext.Provider value={{ isLogin, login, checkLogin, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}