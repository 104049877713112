import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AuthRoutes from './routes/authRoutes'
import UnAuthRoutes from './routes/unAuthRoutes'
import Error from './pages/Errors/error'
import { useAuth } from './context/authContext'
import Login from './pages/Login/login'
import Overview from './pages/Dashboard/overview'
import Layout from './layout/layout'
import { Notifications } from 'react-push-notification'


const App = () => {
  const { isLogin, checkLogin } = useAuth()

  useEffect(() => {
    let doc = document.querySelector("html")
    let isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

    if(doc.getAttribute("data-bs-theme") == null) doc.setAttribute("data-bs-theme", isDarkMode ? "dark" : "light")
  })

  useEffect(() => {
    checkLogin()
  }, [])

  return (
    <>
      <Notifications />
      <Router>
        <Routes>
            <>
              {AuthRoutes}
              {UnAuthRoutes}
              <Route key="Error" path="*" element={<Error auth={isLogin} code={404} />}></Route>
              <Route key="Default" path="/" element={
                (!isLogin ? <Login /> : <Layout><Overview /></Layout>)
              }/>
            </>
        </Routes>
      </Router>
    </>
  )
}

export default App