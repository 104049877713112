import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { fetchChatbotLeads } from "../../actions/commonController"
import { Button } from "react-bootstrap"

const ChatbotLeads = () => {
    const [data, setData] = useState({})

    const loadData = async() => {
        try {
            setData({})
            let { status, data, error } = await fetchChatbotLeads()
            if(status == "success") setData(data)
        } catch (err) {
            toast.error(err.message)
        }
    }


    useEffect(() => { loadData() }, [])

    return (
        <div className="row">
            <div className={`col-12 pt-`}>
                <div className="card">
                    <div className="card-header">
                        <h4 className="header-title m-0">
                            Chatbot Leads -  List
                            <Button style={{ marginLeft: "10px" }} onClick={loadData}>Click here to refresh</Button>
                        </h4>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <table className="table table-striped dt-responsive nowrap w-100">
                                <thead>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Salary</th>
                                        <th>Employer Name</th>
                                        <th>Date of Birth</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 && data.map((item, index) => (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.phone}</td>
                                                <td>{item.email}</td>
                                                <td>{item.salary}</td>
                                                <td>{item.empname}</td>
                                                <td>{(new Date(item.dob)).toLocaleDateString('en-GB')}</td>
                                                <td>{(new Date(item.createdAt)).toLocaleDateString('en-GB') + ' ' + (new Date(item.createdAt)).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatbotLeads