import React from "react"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <script>document.write(new Date().getFullYear())</script> © Wizhull - Data Viewer (Beta)
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer