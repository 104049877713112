import React from "react"

const About = () => {
    return (
        <>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title-box">
                            <h4 class="page-title">About Fintrek Marketing - Call Recording System</h4>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <p>
                        Our call recording system is a powerful tool designed to capture and manage phone conversations within our organization, 
                        fostering improved communication, performance monitoring, and collaboration. Our mission is to empower our teams to 
                        achieve operational excellence through enhanced communication and insights gained from recorded calls. With this system, 
                        we aim to streamline processes, boost productivity, and ensure seamless teamwork. If you have any questions or require further 
                        information, please don't hesitate to contact us.
                    </p>
                </div>
            </div>
        </>
    )
}

export default About